<template>
    <div id="app">
        <el-menu ref="menu" mode="horizontal" router>
            <el-menu-item>Sven V2</el-menu-item>
            <el-menu-item index="/">Balance</el-menu-item>
            <el-menu-item v-if="isAdmin" index="/manage">Manage</el-menu-item>
            <el-menu-item v-if="isAdmin" index="/logging">Logging</el-menu-item>
        </el-menu>
        <router-view @freshMenu="freshMenu"/>
    </div>
</template>

<script>
import VueCookies from 'vue-cookies'

export default {
    methods: {
        freshMenu() {
            var token = VueCookies.get('token')
            if (token && token.startsWith('admin')) {
                this.isAdmin = true
                this.$forceUpdate()
            }
        }
    },
    data() {
        return {
            isAdmin: false
        }
    },
    mounted() {
        this.freshMenu()
    },
    updated() {
        this.$refs.menu.activeIndex = this.$route.path
    }
}
</script>

<style lang="scss">
    body {
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-table td, .el-table th {
        padding: 6px 0;
    }
    .el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
        padding-left: 5px;
    }
    .el-table .cell {
        padding-left: 5px;
        padding-right: 5px;
    }
    .el-menu-item {
        padding: 0 10px;
    }

    .el-submenu__title {
        padding: 0 10px;
    }

    .el-menu--popup {
        min-width: 100px;
    }

    .el-menu--horizontal {
        > .el-menu-item {
            height: 40px;
            line-height: 40px;
        }

        > .el-submenu .el-submenu__title {
            height: 40px;
            line-height: 40px;
        }
    }
    .el-table__expanded-cell[class*=cell] {
        padding: 10px 5px;
    }
    .num-red {
        color: #F56C6C
    }
    .num-green {
        color: #67C23A
    }
</style>
