import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)
VueCookies.config('30d')

function _(name) {
    return () => import(`@/views/${name}.vue`)
}

const routes = [
    {
        path: '/',
        name: 'balance',
        component: _('Balance'),
        meta: {auth: true}
    },
    {
        path: '/sign-in',
        name: 'signIn',
        component: _('SignIn'),
        meta: {auth: false}
    },
    {
        path: '/logging',
        name: 'logging',
        component: _('Logging'),
        meta: {auth: true}
    },
    {
        path: '/manage',
        name: 'manage',
        component: _('Manage'),
        meta: {auth: true}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

router.beforeEach((to, from, next) => {
    let query = to.query
    if (query.hasOwnProperty('token')) {
        VueCookies.set('token', query.token)
        delete query.token
        next({path: to.path, query: query})
        return
    }
    // if (process.env.NODE_ENV !== 'production') {
    //     // console.log('escape token check on none production env')
    //     next()
    //     return
    // }
    if (to.meta.auth && !VueCookies.get('token')) {
        localStorage.setItem('after-login', to.fullPath)
        next('/sign-in')
        return
    }
    next()
})

export default router
